@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  overflow-y: scroll;
}
body{
  font-family: system-ui,  'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.how-clik{
 width: 100%;
    height: 400px;
    margin: 0 auto;
    padding: 10px;
}
.dropdown{
  width: 100%;
}
.font-bold{
  font-weight: bold;
}
.personal_need_table tr:hover{
  box-shadow: -1px -1px 10px 0px #2b4a84;
}
.mobile-i3mage:hover {
  box-shadow: -4px -1px 20px 0px #2497f3;
}
.comp_logo{
  width: 12rem;
}
.mainLogo{
  background-color: #2b75e5;
  padding: 1.5rem;
  border-radius: 36px;
  margin-top: 2.25rem;
  width: 15rem;
}
.get-cash-now {
  background-color: #2b75e5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff !important;
  border-radius: 8px;
  box-shadow: 10px 6px 0px 0px #0497ff;
  border: 0.1px solid white;
}
#menuBox{
  position: absolute;
  width: auto;
  border: 1px solid black;
  margin-top: 5px;
  /* left: 0; */
  right: 22px;
  align-items: center;
}
/* .per-loan-back{
  background-image: url('./assets/8.jpg');
} */
.get-cash-now:hover {
  background-color: #426097;
}

.get-cash-now-whats {
  background-color: #f9bd08;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 190px;
}

.header-wrapper {
  align-items: center;
  height: 100px;
  position: sticky;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  top: 0;
  padding: 25px;
}
#floating-icon-android{
  position: fixed;
  right: 0;
  top: 20rem;
}
#floating-icon-ios{
  position: fixed;
  right: 0;
  top: 24rem;
}

.mobile-image:hover {
  box-shadow: -4px -1px 20px 0px #2497f3;
}

.home:active {
  color: #f9bd08;
  font-style: italic;
}

.home:focus {
  color: #f9bd08;
}



.main-wrapper{
    /* background-image: url('./assets/sli1.jpg'); */
    background-color: white;
    color: black;
  }
.number-list {
  text-align: left;
  align-items: center;
  width: 33%;
  margin: 0 auto;
  list-style: number;
}

.list-item-demo {
  margin-top: 30px;
  margin-left: 50px;
  font-size: 20px;
}

.homepage-wrapper {
  padding: 10px 16px 10px 16px;
  margin-top: 20px;
}

.text-white {
  color: white;
}

.clickfin-para-home {
  margin-top: 20px;
  font-size: 20px;
  box-shadow: 1px 1px 0px #999, 2px 2px 0px #999, 3px 3px 0px #999,
  4px 4px 0px #999, 5px 5px 0px #999, 6px 6px 0px #999;

}
.flex.p-5.wrapper-gap a div {
  font-size: 1.1vw;
  font-family: serif;
  font-weight: 600;
  color: #000000;
  line-height: 2;
}

.footer {
  padding: 10px;
  color: white;
  background-color: #2b75e5;
}
.footer-wrapper{
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
.pers-loan {
  background-color: #2b4a84;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid;
}

td,
tr {
  text-align: left;
  padding: 8px;
}

th {
}

tr:nth-child(even) {
  background-color: #2b4a84;
  color: white;
}
#getInstantCashButton{
  width: auto;
  height: auto;
  margin-left: auto;
}

.get-loan-now {
  background-color: #426097;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
}

.get-loan-now:hover {
  background-color: #2b4a84;
  color: white;
}

table,
th,
td {
  border: 1px solid black;
}

th,
td {
  padding: 20px;
  text-align: left;
}


.faqs {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #2b75e5;
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
  color: white;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}


.our-mission {
  background-color: #2b4a84;
  padding: 10px;
  width: 75%;
  margin: 0 auto;
  color: aliceblue;
  margin-top: 30px;
  border-radius: 20px;
}

.ETableCellText {
  background-color: #2b4a84;
  font-size: 16px;
}

.EtableCellValue {
  background-color: #2b4a84;
  font-size: 16px;
}

.phone-input {
  border: 1px solid;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  padding: 10px;
}

.proceed {
  text-align: center;
  background-color: green;
  padding: 10px;
  border-radius: 10px;
  color: white;
}

#drawer-toggle {
  opacity: 0;
}

#drawer-toggle-label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 0px;
  height: 50px;
  width: 50px;
  display: block;
  position: fixed;
  z-index: 1;
}

#drawer {
  position: fixed;
  top: 0;
  left: -300px;
  height: 100%;
  background: #FFA000;
  padding: 20px;
  -webkit-overflow-scrolling: touch;
  color: white;
}

#page-content {
  margin-left: 0px;
  margin-top: 50px;
  width: 100%;
  height: calc(100% - 50px);
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 20px;
}

#drawer-toggle:checked ~ #drawer-toggle-label {
  height: 100%;
  width: calc(100% - 300px);
  background: rgba(255, 255, 255, 0.8);
}
.emi-glance{
  display: flex;
  flex-direction: row;
}
#drawer-toggle:checked ~ #drawer-toggle-label,
#drawer-toggle:checked ~ header {
  left: 300px;
}

#drawer-toggle:checked ~ #drawer {
  left: 0px;
  top: 65px;
  border-radius: 25px;
}

#drawer-toggle:checked ~ #page-content {
  margin-left: 300px;
}

#drawer ul {
  list-style-type: none;
}

#drawer ul a {
  display: block;
  padding: 10px;
  color:white;
  text-decoration: none;
}

#drawer ul a:hover {
  color:#2b4a84;
}
.wrapper-gap{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainHomePage{
width: 50%;
}
.contact_add{
display: flex;
flex-direction: row;
justify-content: center;
gap: 20rem;
}
.contact_sub{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.about{
width: 50%;
}
.welcome{
  margin-top: 10rem;
}
.paragraph{
  margin-top: 4rem;
}
.para-3{
  display: flex;
  flex-direction: row;
}
.homeLogo{
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
}
.homeLogo div:hover{
  box-shadow: 0px 1px 12px 1px #0dcaf0;
  margin: 2px;
  
}
.grav-text{
  padding: 3rem;
}
.our-partner{
  margin-top: 3rem;
}
.contact-header{
  margin-top: 10rem;
}
.list-icon-home{
  margin-left: 10%;
}
@media only screen and (min-width: 756px) and (max-width: 1100px) {
  .metro{
    position: relative;
    left: 20%;
  }
}
@media only screen and (min-width: 1101px){
  .metro{
    position: relative;
    left: 38%;
  }
}
@media only screen and (max-width: 600px) {
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 100%;
  }
  .list-icon-home{
    margin-left: 0 !important;
  }
  .contact-header{
    margin-top: 1rem;
  }
  .our-partner{
    margin-top: 1rem;
  }
  .grav-text{
    padding: 1rem;
  }
  .grav-off-det{
    text-align: center;
  }
  .heading-2{
    padding-left: 2px;
    padding-right: 2px;
  }
  .para-3{
    flex-direction: column;
  }
  .para-2 p {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .welcome{
    margin-top: 2rem;
  }
  .paragraph{
    margin-top: 0;
  }
  .mainHomePage{
    width: 100%;
  }
  .contact_add{
    flex-direction: column;
    gap: 0;
  }
  .about{
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .unique{
    display: flex;
    flex-direction: column;
  }
.get-cash-now {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: #00ff89 !important;
    background: #fafafa;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    box-shadow: none;
    font-size: 3vw !important;
    font-weight: 600;
  }
  
  .get-cash-now:before {
    content: "";
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }
  
  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  .get-cash-now:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }
  .emi-glance{
    flex-direction: column;
  }
  .hide-sidebar{
  visibility: hidden;
  }
  .wrapper-gap {
    justify-content: center;
    align-items: center;
    display: none;
  }

  #drawer-toggle-label:before {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: #d9ff07;
    left: 13px;
    top: 18px;
   box-shadow: 0 6px 0 #d9ff07, 0 12px 0 #f9f208;
  }
  

  header {
    width: 100%;
    position: fixed;
    left: 0px;
    background: #efefef;
    padding: 10px 10px 10px 50px;
    font-size: 30px;
    line-height: 30px;
    z-index: 0;
    display: block;
  }

}
@import url(https://fonts.googleapis.com/css?family=Lato:400,600,700);

.snip1527 {
  color: black;
  float: left;
  font-family: "Lato", Arial, sans-serif;
  font-size: 16px;
  margin: 10px 1%;
  max-width: 310px;
  min-width: 250px;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;
  max-width: 300px;
}

.snip1527 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.snip1527 img {
  max-width: 100%;
  vertical-align: top;
  position: relative;
}

.snip1527 figcaption {
  padding: 25px 20px 25px;
  bottom: 0;
  z-index: 1;
}

.snip1527 figcaption:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  content: "";
  opacity: 0.8;
  z-index: -1;
}
.snip1527:hover img,
.snip1527.hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.testmonials{
  display: flex;
  flex-direction: row;
}
.main-home{
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
}
.about-main{
  display: flex;
  flex-direction: row;
}
.about-para{
  display: flex;
  flex-direction: row;
}
.last-about-para{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.main-per{
  display: flex;
  flex-direction: row;
}
.emi-main{
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
.tabs-emi{
  flex-direction: row;
}
.faq-section{
  display: flex;
  flex-direction: row;
}
.example{
  width: 33%;
}
.contact-main{
  display: flex;
  flex-direction: row;
}
.no-contra{
  width: 50%;
}
.blog-main{
  padding: 20px;
}

.sticky-header{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
}
.login-page{
  display: flex;
  flex-direction: row;
  padding: 20px;
}
.one-login{
  width: 50%;
}
.flex-one{
  display: flex;
  flex-direction: row;
}
.margin-top-layout{
  margin-top: 20px;
  gap:20px
}
.middle-basic{
  justify-content: center
  ;
}
.select-drop-wrapper{
  display: flex;
  flex-direction: row;
}
@media (max-width: 800px) {
  .testmonials{
    flex-direction: column;
  }
.select-drop-wrapper{
  display: flex;
  flex-direction: column;
}
  .login-page{
    display: flex;
    flex-direction: column;
    
  }
  .margin-top-layout{
    margin-top: 10px;
  }
  .flex-one{
    display: flex;
    flex-direction: column;
  }
  .blog-main{
    padding: 0px;
  }
  .one-login{
    width: 100%;
  }
  .faq-section{
    flex-direction: column;
  }
  .number-list{
    width: 100%;
  }
  .contact-main{
    flex-direction: column;
  }
  .example{
    width: 100%;
    padding: 20px;
  }
  .tabs-emi{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 20px;
  }
 
  .emi-main{
    flex-direction: column;
  }
  .main-per{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about-para{
    flex-direction: column;
  }
  .last-about-para{
    flex-direction: column;
  }
 .about-main{
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
 }
  .main-home{
    flex-direction: column;
   justify-content: center;
   align-items: center;
   display: flex;
   margin-top: 0;
  }
  .footer-wrapper{
    flex-direction: column;
  }
  .no-contra{
    width: 100%;
  }
  /* .footer-logo{
    text-align: center;
    display: flex;
    justify-content: center;
  } */
 
}